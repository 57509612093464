:root {
    --footer-font-size: 0.8em;
}


footer {
    padding: 2rem 2rem 1rem 2rem;
    background-color: #EFF7FF;

}

.upper {
    display: flex;
    justify-content: space-around;
}

.info {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.info span {
    display: flex;
    align-items: center;
}


.icon {
    width: 36px;
    margin-top: 2px;
    margin-right: 8px;
}
.icon:nth-child(2) {
    margin-right: 15px;

}

.links,.link {
    display: flex;
}
.links {
    flex-grow: 1;
    justify-content: space-around;
}
.link {
    flex-direction: column;
}

.downloads {
    display: none;
}

.links span p {
    margin-block-start: 5px;
    margin-block-end: 5px;
    font-family: "Poppins";
    font-size: var(--footer-font-size);
}

.links span a {
    margin-bottom: 5px;
    font-family: "Poppins";
    font-size: var(--footer-font-size);
    text-decoration: none;
    color: black;
}


.link-heading {
    font-weight: bold;
}

.lower {
    text-align: center;
}
/* 
footer {
    width: 100%;
    height: fit-content;
    padding: 2rem 0px;
    display: grid;
    grid-template-rows: 90% 10%;
    background-color: #EFF7FF;
    z-index: 2;
}

footer div {
    width: 80%;
    margin: 0px auto;
}

.upper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: auto;
}

.info {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    width: 60%;
}

.info-img {
    width: 150px !important;
    height: auto;
}

.info span {
    height: 50px;
    width: 180px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.info span a img {
    border-radius: 50%;
    width: 40px;
    height: auto;
    cursor: pointer;
}


.links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 5rem;
}

.links span:nth-child(2) {
    margin: 0px 5rem;
    width: fit-content;
}

.links span {
    display: flex;
    flex-direction: column;
}

.links span p {
    margin-block-start: 5px;
    margin-block-end: 5px;
    font-family: "Poppins";
    font-size: var(--footer-font-size);
}

.links span a {
    margin-bottom: 5px;
    font-family: "Poppins";
    font-size: var(--footer-font-size);
    text-decoration: none;
    color: black;
}

.link-heading {
    font-weight: bold;
}

.downloads {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: fit-content;
    display: none;
}

.downloads p {
    margin-block-start: 0px;
    font-family: "Poppins";
    font-size: var(--footer-font-size)
}

.downloads a {
    width: 100px;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: var(--footer-font-size);
    background-color: #334565;
    color: white;
    padding: 2px 5px;
    margin-bottom: 5px;
    text-decoration: none;
    border-radius: 5px
}

.lower {
    text-align: center;
}

.lower p {
    font-size: 0.7em;
    font-family: "Poppins";
}

.links span {
    align-items: center;
} */
/* 
@media screen and (max-width: 900px) {
    footer div {
        width: 90%;
    }
    .links {
        padding-right: 1rem;
    }
    .links span:nth-child(2) {
        margin: 0px 4rem;
    }
} */

@media screen and (max-width:800px) {
    footer {
        padding: 0;
    }
    .upper {
        flex-direction: column;
    }
    .lower {
        display: flex;
        justify-content: center;
        align-items: center
    }
    .downloads {
        display: none;
    }
    .info {
        display: flex;
        flex-direction: column!important;
        align-items: center!important;
    }
    .links {
        display: flex;
        flex-direction: column!important;
        align-items: center;
        padding: 0!important;
        line-height: 2;

    }
    .link {
        align-items: center;
    }
    .link-heading {
        text-align: center;
    }
    .link span p {
        margin-block-start: 0;
    }
}

/*
@media screen and (max-width:600px) {
    .upper {
        flex-direction: column;
    }
    .upper div {
        margin-bottom: 1rem;
    }
    .info,
    .downloads {
        width: 100%;
        flex-direction: row;
        justify-content: space-evenly;
    }
    .link {
        padding-right: 0px;
        justify-content: space-around;
        align-items: center;
    }
    .links span {
        margin: 0px !important;
        padding: 0px !important;
    }
}



@media screen and (max-width:400px) {
    .info {
        flex-direction: column;
    }
    .downloads {
        flex-direction: column;
    }
} */
