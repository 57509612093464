.LoginBox {
    width: 300px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    position: absolute;
    left: 0%;
    right: 0%;
    bottom: 0%;
    top: 75%;
    margin: auto;
    padding: 1.5rem;
    z-index: 2;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px #00000040;
    font-family: "Poppins";
}

.top-section {
    width: 100%;
    text-align: center;
    z-index: 2;
}

.top-section p,
.top-section h3 {
    margin-block-start: 0px;
    margin-block-end: 0px;
}

.top-section h3 {
    font-size: 1.8em;
    margin-bottom: 5px;
    color: rgba(71, 79, 133, 1);
}

.top-section p {
    font-size: 0.75em;
    font-weight: bold;
}

.input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 8px 0px;
    z-index: 2;
}

.input-username {
    width: 100%;
    outline: none;
    padding: 12px 10px !important;
    border: 1px solid rgba(81, 227, 212, 1);
    background-color: rgb(81, 227, 212, 0.2);
    margin-bottom: 10px;
}

.input-container span {
    width: 100%;
    height: fit-content;
    margin: 1rem 0px;
    display: grid;
    grid-template-columns: 80% 20%;
    border: 1px solid rgba(81, 227, 212, 1);
}

.input-password {
    outline: none;
    border: none;
    width: 100%;
    margin-top: 0px !important;
}

.toggle-password {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
}

.input-container span,
.input-username,
.signin-btn {
    padding: 10px 10px;
    border-radius: 10px;
    font-family: "Poppins";
    font-size: 0.75em;
}

.input-password {
    font-size: 1em !important;
    margin-bottom: 0px;
    font-family: "Poppins";
}

.input-username,
.input-password {
    font-weight: bold;
}

.input-username:focus,
.input-container span:focus {
    box-shadow: 0px 0px 3px 0px #008c97b5;
}

.input-container p {
    font-size: 0.7em;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin: auto;
}

.input-container p svg {
    height: 18px;
    width: 20px;
    vertical-align: middle;
    margin-top: -4px;
}

.resend_code_btn {
    background-color: transparent;
    border: none;
    padding: 0px;
    color: blue;
    font-family: "Poppins";
    font-size: 1em;
    cursor: pointer;
}

.resend_code_btn:hover {
    text-decoration: underline;
}

.signin-btn {
    border: none;
    background: #51E3D4;
    border: none;
    margin-top: 10px;
    padding: 0.8rem 10px;
    width: 102%;
    box-shadow: 0px 4px 9px 1px #02097e2b;
    color: #474F85;
    font-weight: bold;
    cursor: pointer;
    transition: 0.4s;
}

.signin-btn:hover {
    transform: scale(1.03);
    color: white;
    font-weight: bold;
}

.bottom-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.bottom-section p {
    margin-block-start: 0px;
    margin-block-end: 0px;
    font-size: 0.55em;
}

.bottom-section p a {
    text-decoration: none;
    color: black;
    font-weight: bold;
}

.bottom-section p a:hover {
    text-decoration: underline;
}

.hidden {
    display: none !important;
}

.ToggleBox {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 8px;
}

.ToggleBox span {
    font-family: "Poppins";
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ToggleBox span label {
    font-size: 0.8em;
    margin-right: 5px;
}

#visible,
#invisible {
    color: rgba(81, 227, 212, 1);
}

.otp-box {
    width: 100%;
    display: flex !important;
    flex-direction: row;
    justify-content: space-evenly;
    border: none !important;
}

.otp-box div {
    width: 100%;
    justify-content: space-evenly;
}

.otp-box div div input {
    padding: 8px 10px !important;
    font-size: 1.5em;
    font-family: "Poppins";
    border-radius: 10px;
    outline: none !important;
    background-color: rgba(12, 206, 186, 0.2);
    border: 1px solid rgba(81, 227, 212, 1);
}

.input-grade {
    width: 100%;
    accent-color: rgb(1, 100, 104);
}

.download-btn {
    background-color: #5ce0d2 !important;
    color: white !important;
    font-weight: bolder !important;
    border: none;
    transition: 0.2s !important;
}

.download-btn:hover {
    transform: scale(1.05);
    background-color: #41a59b !important;
}

@media screen and (max-width: 800px) {
    .links {
        padding-right: 1rem;
    }
    .ToggleBox button {
        left: 40vw;
    }
}

@media screen and (max-width:400px) {
    .LoginBox {
        width: 80%;
        padding: 0.9rem;
        padding-right: 1.2rem;
    }
    .ToggleBox button {
        left: 30vw;
    }
}