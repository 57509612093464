.Login {
    margin: 0px;
    width: 100%;
    height: 60%;
    position: absolute;
    top: 0px;
    z-index: -1;
}

.bg-1 {
    width: 100%;
    height: 150%;
}

.wave {
    position: absolute;
    top: 17%;
    height: 55vh;
    width: 100%;
    opacity: 0.4;
    background-color: transparent;
}

.navbar_component {
    position: sticky;
    top: 0px;
}